import React from "react";
import assets from "./assets";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div className="intro-logo">
          <img
            src={assets.symbolWhite}
            className="App-logo"
            alt="logo"
            style={{
              width: "30%",
            }}
          />
          <img
            src={assets.logoWhiteName}
            alt="logo"
            style={{
              width: "70%",
              marginLeft: "3%",
            }}
          />
        </div>
        <p className="intro-text">
          Making your buisness <code>awesome</code> with technology.
        </p>
      </header>
    </div>
  );
}

export default App;
