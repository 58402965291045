import blackLogo from "./logoBlack.svg";
import whiteLogo from "./logoWhite.svg";
import symbolWhite from "./symbolWhite.svg";
import symbolBlack from "./symbolBlack.svg";
import logoWhiteName from "./logoWhiteName.svg";
import logoBlackName from "./logoBlackName.svg";

export default {
  whiteLogo,
  blackLogo,
  symbolWhite,
  symbolBlack,
  logoWhiteName,
  logoBlackName,
};
